import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import { Link } from 'gatsby'
import privacyPolicy from '../../assets/images/banner-img2.png'

const CookiePolicy = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Cookie Policy" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Cookie Policy" 
            />
            <section className="privacy-policy-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="privacy-policy-content">
                                <img src={privacyPolicy} alt="pp" />
                                <h3><i>Cookie Statement</i></h3>
                                <blockquote className="blockquote">
                                    <p>Neolytics.io Holding Limited and its affiliates (listed in the “Affiliates” section in our Privacy Policy (collectively “Neolytics.io” “us” and “we”) and third parties we partner with use cookies and similar technologies on Neolytics.io websites (including mobile sites) and social media sites (“Sites”), mobile applications (Apps) (collectively “Online Services”) to give users (“you” or “your”) the best possible browsing experience.</p>
                                </blockquote>
                                <h3>1. What are cookies?</h3>
                                <p>
                                    A cookie is a small text file, placed on your PC, tablet or mobile phone (collectively “devices” and individually “a device”) when you visit an online service. Cookies send information back to the originating online service or another online service that recognises that cookie.
                                    Cookies can be either permanent (persistent cookies) and remain on your device until you delete or clear them, or temporary (session cookies) where they stay until you close your browser.
                                    Cookies can also be either first-party cookies set by the online service you are visiting or third-party cookies set by an online service other than the online service you are visiting.
                                </p>
                                <p>Cookies help us to do things such as:</p>
                                <ul>
                                    <li>identify your device when you use our Online Services;</li>
                                    <li>identify which browser you use;</li>
                                    <li>identify which specific pages you view and the time and date you visited our Online Services;</li>
                                    <li>remember you when you return;</li>
                                    <li>provide you with a faster, better, and safer browsing experience;</li>
                                    <li>keep track of your preferences;</li>
                                    <li>provide you with a more personalised experience; and</li>
                                    <li>measure and analyse traffic patterns and user behaviour on our Online Services.</li>
                                </ul>
                                <h3>2. How we use cookies</h3>
                                <p>We use different types of cookies for various purposes, including the following:</p>
                                <p>Sign-in and Authentication – to verify your account, remember when you sign in so that you don’t need to keep signing in and keep you logged in to navigate easily between pages on our Online Services.</p>
                                <p>Your Preferences and Settings – to help us remember your preferences, including your preferred language, browser settings and communication and opt-out preferences so that they do not have to be reset each time you return to our Online Services.</p>
                                <p>Performance – to assess our Online Services’ performance and improve the features, functions, content, and design of our Online Services.</p>
                                <p>Analytics and Research – to collect usage and performance data about our Online Services. For example, we may use cookies to count the number of unique visitors to pages on our Sites, what content was viewed and what links were clicked on, to log users’ IP addresses to analyse trends and to compile other statistics about the use of our Online Services and user behaviour. These cookies include our cookies and third-party cookies.</p>
                                <p>Security – to enable and support our security features, keep your account safe, and help us combat fraud and any activity on our Online Services that violates our policies or applicable law.</p>
                                <h3>3. Cookies and similar technologies used by third parties and us</h3>
                                <p>We use tools like cookies, web beacons, analytics services, and advertising providers to gather the data listed above. Some of these tools offer you the ability to opt out of data collection.</p>
                                <h3>4. What We Use Your Data For</h3>
                                <ol>
                                    <li>Responding to your questions and concerns;</li>
                                    <li>Sending you administrative messages and information, including messages from account managers regarding your account, notifications about changes to our Service, and updates to our agreements;</li>
                                    <li>Sending push notifications to your wireless device to provide updates and other relevant messages;</li>
                                </ol>
                                <h3>5. Your Choices About the Use of Your Data</h3>
                                <p>If you don’t want to receive cookies adjust your cookie preferences, most browsers allow you to change your browser settings to block or delete cookies. Your browser’s “help” function will tell you how to do this.
                                   You should be aware that if you block or delete cookies, our Online Services may not work correctly, and you may not be able to access certain areas.
                                </p>
                                <h3>6. Updates to this Cookies Statement</h3>
                                <p>We may update this cookie statement at any time. If we do, we will update the “last modified” section at the bottom of this Cookies Statement.
                                    We encourage you to regularly review this Cookies Statement to stay informed about our use of cookies.
                                </p>
                                <h3>7. Contact Neolytics.io</h3>
                                <p>
                                    If you have any queries regarding this Cookies Statement, please contact us. You can reach us at info@neolytics.io.
                                </p>
                                <p><i>This Cookie Statement/Policy was last updated on September 17, 2023.</i></p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li>
                                            <Link to="/legal/terms-of-use">
                                                Terms of Use
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/legal/privacy-policy">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li className='active'>
                                            <Link to="/legal/cookie-policy">
                                                Cookie Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/legal/other-policies">
                                                Other Policies
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                {/* <div className="widget widget_recent_courses">
                                    <h3 className="widget-title">Recent Courses</h3>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg1" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$49.00</span>
                                            <h4 className="title usmall"><Link to="#">The Data Science Course 2020: Complete Data Science Bootcamp</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg2" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$59.00</span>
                                            <h4 className="title usmall"><Link to="#">Java Programming MasterclassName for Software Developers</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg3" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$69.00</span>
                                            <h4 className="title usmall"><Link to="#">Deep Learning A-Z™: Hands-On Artificial Neural Networks</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>
                                </div> */}

                                <div className="widget widget_tag_cloud">
                                    <h3 className="widget-title">Popular Tags</h3>

                                    <div className="tagcloud">
                                        <Link to="/about-us">who is Neolytics.io? <span className="tag-link-count"> (3)</span></Link>
                                        <Link to="/contact">how do I contact Neolytics.io? <span className="tag-link-count"> (3)</span></Link>
                                        {/* <Link to="#">Design <span className="tag-link-count"> (3)</span></Link>
                                        <Link to="#">Braike <span className="tag-link-count"> (2)</span></Link>
                                        <Link to="#">Fashion <span className="tag-link-count"> (2)</span></Link>
                                        <Link to="#">Travel <span className="tag-link-count"> (1)</span></Link>
                                        <Link to="#">Smart <span className="tag-link-count"> (1)</span></Link>
                                        <Link to="#">Marketing <span className="tag-link-count"> (1)</span></Link>
                                        <Link to="#">Tips <span className="tag-link-count"> (2)</span></Link> */}
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default CookiePolicy;